<template>
  <div class="h-full">
    <div class="bottom_space rounded py-2 h-full bg-white">
      <div v-if="leadDetails !== null">
        <div style="overflow-y: autos; white-space: nowrap;" class="lg:grid grid-cols-4 gap-4">
          <div class="col-span-2 ">
            <div>
              <div class="card rounded-md p-2">
                <div class="flex items-center justify-between">
                  <div class="text-text1" :class="mobileView ? 'heading-4' : 'heading-3'">● Lead Details:</div>
                </div>
                <div class="flex text-right justify-end   text-text2 whitespace-pre-line" v-if="leadDetails.crmLeadSendLastLog.lastSendDate !== ''">
                    <div class="mt-1" v-if="leadDetails.crmLeadSendLastLog.apiResponse.success">
                      <span @click="redirectoURL(leadDetails.crmLeadSendLastLog.apiResponse.lead_id)" class="text-primary cursor-pointer" :class="mobileView ? 'heading-6' : ''" v-if="leadDetails.crmLeadSendLastLog.apiResponse.lead_id !== ''">https://www.ftmcrm.com/leads/edit/{{leadDetails.crmLeadSendLastLog.apiResponse.lead_id}}</span>
                      <div class="flex items-start ">
                        <span><i class="fa-solid fa-circle-check text-success pr-1"></i></span>
                        <span :class="mobileView ? 'heading-6' : ''">This lead is sent to ftmcrm on {{leadDetails.crmLeadSendLastLog.lastSendDate | formatDate}}</span>
                      </div>
                    </div>
                  <div class="flex items-start mt-1" v-if="!leadDetails.crmLeadSendLastLog.apiResponse.success">
                    <div class="group">
                      <p><i class="fa-solid fa-circle-xmark text-error pr-1 cursor-pointer"></i></p>
                        <p class="invisible group-hover:visible bg-white absolute p-2 rounded-md card">
                          <span class="mb-0 text-error">Error: {{ leadDetails.crmLeadSendLastLog.apiResponse.error }}</span>
                        </p>
                      </div>
                    <span :class="mobileView ? 'heading-6' : ''">This lead is sent to ftmcrm on {{leadDetails.crmLeadSendLastLog.lastSendDate | formatDate}}</span>
                  </div>
                </div>
                <div class="mt-1">
                    <div class="grid lg:grid-cols-2">
                      <div class="p-1">
                        <p class="mb-0">
                          <span class="text-gray4 heading-5 "><p class="mb-0" style="width:173px">Campaign Name:</p></span>
                          <span class="text-text2 heading-4 font-semibold whitespace-pre-line" >{{leadDetails.campaignName}}</span>
                        </p>
                      </div>
                      <div class="text-right">
                        <Button class="ml-4" @buttonAction="startCampaign()" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Lead to CRM'"/>
                      </div>
                    </div>
                  </div>
                  <div class="">
                    <div class="py-1">
                      <div class="pb-4  rounded-md p-2 pt-0 my-2">
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">First Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.firstName !== ''">{{leadDetails.firstName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Last Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.lastName !== ''">{{leadDetails.lastName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Cell Number:</p></span>
                            <span class="text-text2 heading-5 font-semibold" >{{leadDetails.phoneNumber | phone}}</span>
                          </p>
                          <!-- <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Lender Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.lenderName !== ''">{{leadDetails.leadOtherDetail.lenderName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p> -->
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Email:</p></span>
                            <span class="text-text2 heading-5 font-semibold overFlowParaA" v-if="leadDetails.leadOtherDetail.email !== ''">{{leadDetails.leadOtherDetail.email}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Address:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.address !== ''">{{leadDetails.leadOtherDetail.address}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">City:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.city !== ''">{{leadDetails.leadOtherDetail.city}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">State:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.state !== ''">{{leadDetails.leadOtherDetail.state}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Zip:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.zip !== ''">{{leadDetails.leadOtherDetail.zip}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-3">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Loan Date:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.loanDate !== ''">{{leadDetails.leadOtherDetail.loanDate | getFullDate}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Loan Type:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.loanType !== ''">{{leadDetails.leadOtherDetail.loanType}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Total Debt:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.totalDebt !== ''">{{leadDetails.leadOtherDetail.totalDebt | amountFormaterWithToFix}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-3">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Interest Rate:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.interestRate !== ''">{{leadDetails.leadOtherDetail.interestRate}}%</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Mortgage Balance:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.mortgageBalance !== ''">{{leadDetails.leadOtherDetail.mortgageBalance | amountFormaterWithToFix}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Fico:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.fico !== ''">{{leadDetails.leadOtherDetail.fico}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                        <div class="grid md:grid-cols-2">
                          <p class="mb-0 pt-2">
                            <span class="text-gray4 heading-6 "><p class="mb-0" style="width:173px">Lender Name:</p></span>
                            <span class="text-text2 heading-5 font-semibold" v-if="leadDetails.leadOtherDetail.lenderName !== ''">{{leadDetails.leadOtherDetail.lenderName}}</span>
                            <span class="text-text2 heading-5 font-semibold" v-else>--</span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="Object.keys(otherDetails).length > 0">
                <div class="mt-2 card rounded-md p-2">
                  <span class="text-text2 heading-5 font-semibold">Other Details:</span>
                  <div class="pb-4 rounded-md p-2 pt-0 my-2">
                    <div class="grid md:grid-cols-2" >
                      <p class="mb-0 pt-2" v-for="(value, key) in otherDetails" :key="key">
                        <span class="text-gray4 heading-6">
                          <p class="mb-0" style="width:173px">{{ key }}:</p>
                        </span>
                        <span class="text-text2 heading-5 font-semibold">{{ value }}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <!-- <div class="pb-4 rounded-md p-2 pt-0 my-2">
                  <template v-if="Object.keys(otherDetails).length > 0">
                      <div class="grid md:grid-cols-2" v-for="(value, key) in otherDetails" :key="key">
                          <p class="mb-0 pt-2">
                              <span class="text-gray4 heading-6">
                                  <p class="mb-0" style="width:173px">{{ key }}:</p>
                              </span>
                              <span class="text-text2 heading-5 font-semibold">{{ value }}</span>
                          </p>
                      </div>
                  </template>
                  <template v-else>
                      <p class="mb-0 pt-2">No other details available</p>
                  </template>
              </div> -->
            </div>
            <div class="col-span-2">
              <div class="mt-2 card rounded-md p-2">
                <div class="py-1">
                  <div class="mb-0 pb-2 flex items-center justify-between">
                    <span class="text-text2 heading-5 font-semibold" >Text Drips:</span>
                    <div class=" text-white cursor-pointer bg-primary px-2 py-1 rounded-xl" @click.stop="leadMessageList()">
                      <i class="fas fa-rotate  heading-6"></i>
                    </div>
                  </div>
                  <div class="w-full">
                    <div class="progress-bar" v-if="searchLoader">
                      <div class="progress-bar-value"></div>
                    </div>
                  </div>
                  <div class="overflow-auto" ref="messageContainer" :style="`max-height:` + cardHeight + `px;overflow: auto; !important`">
                    <div class="px-2" v-for="(data, index) in leadMsgArray" :key="index">
                      <div class="bg-green-50 p-1 rounded-md border " :class="mobileView ?'my-2 ml-8' : 'my-3 ml-12'" v-if="!data.isReply">
                        <div class="p-2 w-full whitespace-pre-line" :class="mobileView ?'heading-6' : 'mt-1'">{{data.messageText}}</div>
                        <p class="mb-0 -mt-1"><span class="text-gray4 text-right justify-end flex pr-2" :class="mobileView ?'heading-7' : 'heading-6'" >Sent: {{data.sendReceiveDate | timeTitleHistory}}</span></p>
                      </div>
                      <div class="bg-red-50 p-1 rounded-md border" :class="mobileView ?'my-2 mr-8' : 'my-3 mr-12'" v-if="data.isReply">
                        <div class="p-2 w-full whitespace-pre-line" :class="mobileView ?'heading-6' : 'mt-1'">{{data.messageText}}</div>
                        <p class="mb-0 -mt-1"><span class="text-gray4 text-right justify-end flex pr-2" :class="mobileView ?'heading-7' : 'heading-6'">Received: {{data.sendReceiveDate | timeTitleHistory}}</span></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="">
                <div class="">
                  <div class="pb-4  card rounded-md p-2 my-2">
                    <TextAreaInput
                        :inputId="'howtoreach'"
                        :inputext="message"
                        :placholderText="`Write your message here`"
                        :lableText="''"
                        :inputType="''"
                        :autoFocus="false"
                        :textMaxlength="1000"
                        :showcharLimit="true"
                        :cols="50"
                        :rows="3"
                        :setReadOnly="false"
                        :fieldError="msgErr !== ''"
                        @keyPressAction="msgErr = ''"
                        @inputChangeAction="(data) => message = data"  />
                        <p class="text-error heading-6">{{msgErr}}</p>
                  </div>
                </div>
                <div class="mt-4 flex justify-end ">
                  <Button class="ml-4" @buttonAction="addNewComp()" :btnSize="'medium'" :textColor="'white'" :btnColor="'primary'" :btnText="'Send Message'"/>
                </div>
              </div>
            </div>
          <!-- <div class="heading-3 text-text1">● Basic Detail:</div> -->
        </div>
      </div>
    </div>
    <button v-show="scY > 200" @click="toTop" class="bg-primary rounded-full h-10 w-10 fixed bottom-3 right-2"><i class="fas fa-caret-up text-white"></i></button>
  </div>
</template>
<script>
import TextAreaInput from '@/View/components/textAreaInput.vue'
import Button from '@/View/components/globalButton.vue'
import ADMINAPI from '@/View/Admin/api/Admin.js'
// import Pagination from '@/View/components/pagination.vue'
import deboucneMixin from '@/mixins/debounce.js'
export default {
  components: {
    Button,
    TextAreaInput
  },
  mixins: [deboucneMixin],
  data () {
    return {
      leadDetails: null,
      otherDetails: null,
      leadMsgArray: [],
      cardHeight: '',
      msgErr: '',
      message: '',
      istart: false,
      readMoreNote: false,
      datePickerVal: false,
      updateExpiryDate: '',
      openEditPopup: false,
      isLoading: false,
      compDetail: null,
      planDetail: [],
      scY: 0,
      scTimer: 0,
      useDetailId: 0,
      isShowFullDesc: true,
      isShowShortDesc: true,
      mobileView: false,
      searchLoader: false,
      competitionDetailObj: [],
    }
  },
  created() {
    window.addEventListener("resize", this.resizeWindowHandler);
  },
  mounted () {
    document.title = 'Lead Detail'
    this.updateExpiryDate = new Date()
    window.scrollTo({top: 0})
    window.addEventListener('scroll', this.handleScroll);
    this.resizeWindowHandler();
    this.leadDetail()
    this.leadMessageList()
    // this.getCompetDetail()
  },
  watch: {
    leadMsgArray: {
      handler() {
        // this.$nextTick(() => {
        //   this.scrollToLastMessage();
        // });
      },
      deep: true,
    },
    competitionDetailObj: {
      handler () {
        console.log('Watch On:-competitionDetailObj', this.competitionDetailObj)
      },
      deep: true
    }
  },
  methods: {
    button1 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'Copied successfully', color: 'success'})
    },
    button2 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'In progress.....', color: 'secondary'})
    },
    button3 () {
      this.$store.dispatch('SetAlert', {showAlert: true, message: 'In progress.....', color: 'secondary'})
    },
    redirectoURL (data) {
      let link = 'https://www.ftmcrm.com/leads/edit/' + data
      window.open(link, '_blank')
    },
    addNewComp () {
      if (this.message === '') {
        this.msgErr = 'Message is required'
      } else {
        this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
        ADMINAPI.SendSmsLeadScreen(
          parseInt(this.$route.query.campaignMasterId),
          parseInt(this.$route.params.leadId),
          this.leadDetails.twilioConfigurationId,
          this.message,
          this.leadDetails.phoneNumber,
          response => {
            this.message = ''
            console.log('FROM MESSAGE RESPONSE.............-->', response)
            this.leadMessageList()
            // this.getCompetDetail()
            this.$store.dispatch('SetLoader', {status: false, message: ''})
          },
          error => {
            this.isLoading = false
            this.$store.dispatch('SetLoader', {status: false, message: ''})
            this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
          }
        )
        // this.$store.dispatch('SetAlert', {showAlert: true, message: 'In progress.....', color: 'secondary'})
      }
    },
    startCampaign () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.SendLeadToCRMApi(
        parseInt(this.$route.query.campaignMasterId),
        parseInt(this.$route.params.leadId),
        response => {
          this.leadDetail()
          this.leadMessageList()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: response.message, color: 'success'})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    leadDetail () {
      this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetLeadDetails(
        parseInt(this.$route.query.campaignMasterId),
        parseInt(this.$route.params.leadId),
        response => {
          this.leadDetails = response.Data
          if (response.Data.leadOtherDetail.additionalData !== '') {
            this.otherDetails = JSON.parse(response.Data.leadOtherDetail.additionalData)
          } else {
            this.otherDetails = ''
          }
          // this.getCompetDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    scrollToLastMessage() {
      console.log('messageContainer', this.$refs.messageContainer)
      if (this.$refs.messageContainer) {
        const messageContainer = this.$refs.messageContainer;
        messageContainer.scrollTop = messageContainer.scrollHeight;
      } else {
        console.log("messageContainer not found");
      }
    },
    leadMessageList () {
      this.searchLoader = true
      // this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
      ADMINAPI.GetLeadMessages(
        0,
        10000,
        parseInt(this.$route.query.campaignMasterId),
        parseInt(this.$route.params.leadId),
        response => {
          console.log('response', response)
          this.leadMsgArray = response.Data.tableRow !== null ? response.Data.tableRow : []
          setTimeout(() => {
            this.scrollToLastMessage();
          }, 100);
          setTimeout(() => {
            this.searchLoader = false
          }, 300);
          // this.getCompetDetail()
          this.$store.dispatch('SetLoader', {status: false, message: ''})
        },
        error => {
          this.isLoading = false
          this.searchLoader = false
          this.$store.dispatch('SetLoader', {status: false, message: ''})
          this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
        }
      )
    },
    smoothScroll (target) {
      window.scrollTo({
        top: document.getElementById(target).offsetTop - 60,
        left: 0,
        behavior: "smooth",
      });
    },
    resizeWindowHandler() {
      this.cardHeight = window.innerHeight - 393
      // if (window.innerWidth < 684) {
      if (window.innerWidth < 1263) {
        this.mobileView = true;
      } else {
        this.mobileView = false;
      }
    },
    handleScroll () {
      if (this.scTimer) return
      this.scTimer = setTimeout(() => {
        this.scY = window.scrollY
        clearTimeout(this.scTimer)
        this.scTimer = 0
      }, 100)
    },
    toTop () {
      window.scrollTo({top: 0, behavior: "smooth"})
    },
    redirectToList() {
      // this.$router.push({name: 'Competition'})
      this.$router.go(-1)
    },
    // getCompetDetail () {
    //   this.isLoading = true
    //   this.$store.dispatch('SetLoader', {status: true, message: 'Loading...'})
    //   ADMINAPI.getOrganizationDetail(
    //     parseInt(this.$route.params.leadId),
    //     response => {
    //       this.competitionDetailObj = response.Data === null ? [] : response.Data
    //       this.compDetail = this.competitionDetailObj.organizationDetail === null ? null : this.competitionDetailObj.organizationDetail
    //       this.planDetail = this.competitionDetailObj.subscriptionDetail === null ? [] : this.competitionDetailObj.subscriptionDetail
    //       this.isLoading = false
    //       console.log("response 123", this.competitionDetailObj)
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //     },
    //     error => {
    //       this.isLoading = false
    //       this.$store.dispatch('SetLoader', {status: false, message: ''})
    //       this.$store.dispatch('SetAlert', {showAlert: true, message: error.message, color: 'error'})
    //     }
    //   )
    // },
  }
}
</script>
<style scoped>

.lableWidth {
  width: 105px;
}
.dotwidth {
  width: 15px;
}
.list_top_card_btn {
  width: 83px !important;
  margin-right: 15px !important;
}
.ImageTag {
  max-height: 300px;
  width: auto;
}
.progress-bar {
  height: 3px;
  background-color: rgb(18,149,186);
  width: 100%;
  overflow: hidden;
}

.progress-bar-value {
  width: 100%;
  height: 100%;
  background-color: rgb(121, 200, 221);
  animation: indeterminateAnimation 1s infinite linear;
  transform-origin: 0% 50%;
}

@keyframes indeterminateAnimation {
  0% {
    transform:  translateX(0) scaleX(0);
  }
  40% {
    transform:  translateX(0) scaleX(0.4);
  }
  100% {
    transform:  translateX(100%) scaleX(0.5);
  }
}
</style>
